import React from "react"
import PageFooter from '../components/PageFooter';


import Img from "gatsby-image"

import pic8 from "../assets/images/robert_meisner_comic.png"

import { Link, graphql } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { rhythm } from "../utils/typography"
import Masonry from 'react-masonry-component'



class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />

        <section id="top" className="one dark cover">
          <div className="container">
            <Bio />
          </div>
        </section>

        <section id="portfolio" className="two">
          <div className="container">
            <header>
              <h2>Thoughts</h2>
            </header>

            <p>
              I love to learn, so I always have some personal side project going on. You can learn more by reading my blog.
            </p>

            <Masonry className="showcase">
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (

                  <article className="item" key={node.fields.slug}>
                    <Link to={node.fields.slug} className="post-card">
                      {node.frontmatter.cover &&
                        <Img
                          fluid={node.frontmatter.cover.childImageSharp.fluid}
                          alt={title}
                        />
                      }
                      <header>
                        <h3
                          style={{
                            marginBottom: rhythm(1 / 4),
                          }}
                        >
                          {title}
                        </h3>
                        <small>{node.frontmatter.date}</small>
                      </header>
                      <section>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </section>
                    </Link>
                  </article>

                )
              })}

            </Masonry>
          </div>
        </section>

        <section id="about" className="three">
          <div className="container">
            <header>
              <h2>About Me</h2>
            </header>

            <img src={pic8} alt="Robert Meisner smiling" className="image featured" />

            <p>
              Tech and software development enthusiast constantly looking for new challenges.
              <br />
              I love building friendly and intuitive solutions.
              <br />
              I’ve got comprehensive experience both in Project management (>1mln USD IT projects) and Software Development.

            </p>
          </div>
        </section>

        <section id="contact" className="four">
          <div className="container">
            <header>
              <h2>Contact me</h2>
            </header>

            <p>
              I’m always open for th new opportunities.
            </p>
            <p>
              <strong>If you need:</strong><br />
              - Project Manager/Product Owner or<br />
              - Business Analyst or<br />
              - Software Developer<br />
              simply contact me at <a href="mailto:robert@catchit.pl">robert@catchit.pl</a>.
              <br /><br />
              Please provide as much details about an offer or a project as you can.
           </p>
            {/*<form method="post" action="#">
              <div className="row">
                <div className="col-6 col-12-mobile">
                  <input type="text" name="name" placeholder="Name" />
                </div>
                <div className="col-6 col-12-mobile">
                  <input type="text" name="email" placeholder="Email" />
                </div>
                <div className="col-12">
                  <textarea name="message" placeholder="Message" />
                </div>
                <div className="col-12">
                  <input type="submit" value="Send Message" />
                </div>
              </div>
            </form>*/}
          </div>
        </section>


        <PageFooter />

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }  filter: {frontmatter: {draft: {ne: true}}} ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
