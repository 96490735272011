/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
import Scroll from './Scroll'
import CV from "../assets/documents/CV_Robert_Meisner_09_2019.pdf"
//import { rhythm } from "../utils/typography"

const Bio = () => {
  	
	/*const data = useStaticQuery(graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`);*/
  
//const { author, social } = data.site.siteMetadata
return (
  
  <div
    //style={{
      //display: `flex`,
      //marginBottom: rhythm(2.5),
    //}}
  >
        <header> 
    <h2 className="alt">
  Hi! I'm <strong>Robert.</strong>
  <br />
  Software engineer and DIY electronics enthusiast.
</h2> 
<p>
  Always open to a new challenges! <a href={CV}>See my CV!</a> <br />
  Have caught your interest? Check out a few things I've done.</p>

    </header>

    <footer>
      <Scroll type="id" element={'portfolio'}>
        <a href="#portfolio" className="button">
          Show me
        </a>
      </Scroll>
    </footer>   
    
  </div>
);
  
}

export default Bio
